<template>
  <div class="survey">
    <van-notice-bar
      left-icon="volume-o"
      text="提示：问卷回答不合格者，审核会驳回。"
      style="margin: 0 -30px;"
    />
    <p class="msg-title">{{errorTitle}}</p>
    <p class="msg-tip">{{errorMessage}}</p>
    <p class="msg-tip" v-if="errorTitle.length>0 && Query.p_status!=1">关注公众号，可以参加更多奖励活动哦！</p>
    <img v-if="errorTitle.length>0" class="qrcode" src="@/assets/img/home/qrcode.jpg">
    <p v-if="errorTitle.length>0" class="msg-qrcode">微信扫一扫关注</p>
  </div>
</template>

<script>
  import { NoticeBar } from 'vant';
  import Vue from "vue";
  Vue.use(NoticeBar)

  export default {
    name: "survey",
    data() {
      return {
        Query: {},
        errorTitle: "",
        errorMessage: ""
      }
    },
    created() {
      this.Query = this.$route.query

    },
    async mounted() {
      this.checkStatus()
    },
    methods: {
      checkStatus() {
        console.log(this.Query)

        if (this.Query.p_status != 1){
          // 通过，但是非提示状态
          this.submitEvent("survey_end", (success, res)=>{
          })
        }

        switch (this.Query.p_status) {
          case "-1":
            this.errorTitle = "很遗憾，此调查现已结束."
            this.errorMessage = "感谢您的关注。"
            break
          case "-2":
            this.errorTitle = "调查暂未开始，请稍后再来."
            this.errorMessage = "感谢您的关注。"
            break
          case "-3":
            this.errorTitle = "您已参与该调查."
            this.errorMessage = "感谢您的关注。"
            break
          case "-4":
            this.errorTitle = "您无权作答，请先加入圈子."
            this.errorMessage = "感谢您的关注。"
            break
          case "1": // 通过，直接重定向到登录
            if (this.Query.join_success) {
              if (this.Query.community_id){
                this.errorTitle = "恭喜您成功加入圈子"
              }else {
                this.errorTitle = "问卷通过"
              }
              this.errorMessage = "立即关注公众号，领取问卷奖励吧~"
            } else {
              this.$router.push({
                path: '/login/survey',
                query: {
                  type: 2,
                  ...this.Query
                }
              })
            }
            break
          case "2":
            this.errorTitle = "很遗憾，问卷不通过"
            if (this.Query.community_id){
              this.errorMessage = "您不符合本次私密圈子的加入条件。"
            }else {
              this.errorMessage = "您不符合本次问卷调查。"
            }
            break
          case "3":
            this.errorTitle = "很遗憾，问卷配额已满"
            this.errorMessage = "暂时无法获得奖励。"
            break
        }
      },
      submitEvent(event, callback){
        let UserInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
        let activityId = this.Query.project_id ? this.Query.project_id : this.Query.union_code
        if (this.Query.cint_arid && !activityId){
          activityId = this.Query.cint_arid
        }
        this.$api.my.submitEvent({
          "event": event,
          "activityId": activityId,
          "userId": UserInfo ? UserInfo.id : "",
          "nickname": UserInfo ? UserInfo.nickName : "",
          "status": this.Query.p_status
        }, (success, res)=>{
          callback(success, res)
        })
      },
    }
  }
</script>

<style scoped>
  .survey {
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    padding: 0 30px;
  }

  .msg-title {
    text-align: center;
    font-size: 40px;
    width: 100%;
    color: #333333;
    padding-bottom: 30px;
    font-weight: bold;
    margin-top: 70px;
  }

  .msg-tip {
    text-align: center;
    font-size: 28px;
    width: 100%;
    color: #969799;
    margin-top: 6px;
  }

  .msg-qrcode {
    text-align: center;
    font-size: 32px;
    width: 100%;
    font-weight: bold;
  }

  .van-button {
    background-color: #3E639A;
    color: #FFFFFF;
  }

  .qrcode {
    width: 85%;
    display: block;
    height: auto;
    margin: 100px auto 0 auto;
  }
</style>
